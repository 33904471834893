import React from 'react';
import { bool } from 'prop-types';

import NumberScaleFilterPlain from './NumberScaleFilterPlain';
import NumberScaleFilterPopup from './NumberScaleFilterPopup';

const NumberScaleFilter = props => {
  const { showAsPopup, marketplaceCurrency, ...rest } = props;
  return showAsPopup ? (
    <NumberScaleFilterPopup {...rest} />
  ) : (
    <NumberScaleFilterPlain {...rest} />
  );
};

NumberScaleFilter.defaultProps = {
  showAsPopup: false,
};

NumberScaleFilter.propTypes = {
  showAsPopup: bool,
};

export default NumberScaleFilter;
